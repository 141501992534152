function scroll_to(clicked_link, nav_height) {
	var element_class = element_class = clicked_link.attr('href').replace('#', '.');
	var scroll_to = 0;
	if(element_class != '.top-content') {
		element_class += '-container';
		scroll_to = $(element_class).offset().top - nav_height;
	}
	if($(window).scrollTop() != scroll_to) {
		$('html, body').stop().animate({scrollTop: scroll_to}, 1000);
	}
}


jQuery(document).ready(function() {
	/*
	    Navigation
	*/
	$('a.scroll-link').on('click', function(e) {
		e.preventDefault();
		//scroll_to($(this), $('nav').outerHeight());
		scroll_to($(this), $('nav').innerHeight());
	});
	// toggle "navbar-no-bg" class
	$('.top-content .text').waypoint(function() {
		$('nav').toggleClass('navbar-white');
		//$('nav').toggleClass('navbar-rainbow');
	});
	
	//Clickable arrow
	$('.clickable-arrow').on('click', function(e) {
		scroll_to($(this), $('nav').innerHeight());
	});

    /*
        Background slideshow
    */
    $('.top-content').backstretch("/assets/img/backgrounds/1.jpg");
    $('.section-4-container').backstretch("/assets/img/backgrounds/1.jpg");

    /*
        Wow
    */
    new WOW().init();

});


function reverse(str) {
    return (str === '') ? '' : reverse(str.substr(1)) + str.charAt(0);
}

function mail_antibot() {
    let address = "lp.metsysamilk@oruib";
    let field = document.getElementById("email-field-hidden");
    field.href = "mailto:" + reverse(address);
    field.innerHTML = reverse(address);
}
